import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  SplitClient,
  SplitFactory,
  SplitTreatments,
} from '@splitsoftware/splitio-react';
import {
  globalstate$,
  splitRootFactory,
  FEATURE_FLAGS,
  /**
   * This theme is used for styling in the old app as there is no styling repo for the old app
   */
  theme,
} from '@smf/ui-main-container-app';
import { ThemeProvider } from '@mui/material';
import { useRxjsState } from '../../hooks/useRxjsState';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import { useEffect, useState } from 'react';

const DigitalTwinLayout = ({ isFromOldApp, children }) => {
  const { rxjsState } = useRxjsState();
  const queryClient = new QueryClient();
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [isThemeReady, setIsThemeReady] = useState(false);

  useEffect(() => {
    /**
     * This dynamic import is to avoid loading styleGuide while loading the digital twin in the old app
     */
    if (!isFromOldApp) {
      import('@iops/uiStyleguide').then(({ lightTheme, darkTheme }) => {
        setCurrentTheme(rxjsState.isDark ? darkTheme : lightTheme);
      });
    }
    setIsThemeReady(true);
  }, [isFromOldApp, rxjsState.isDark]);

  if (!isThemeReady) {
    return <>{MESSAGE_STRINGS['Loading.Indicator']}</>;
  }

  const renderApp = () => {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={currentTheme}>
          {children ? <>{children}</> : <Outlet />}
        </ThemeProvider>
      </QueryClientProvider>
    );
  };
  return (
    <SplitFactory factory={splitRootFactory}>
      <SplitClient
        splitKey={globalstate$._value?.useData?.email?.toLowerCase()}
      >
        <SplitTreatments names={[FEATURE_FLAGS.DT]}>
          {({ isReady }) => {
            return isReady ? (
              renderApp()
            ) : (
              <>{MESSAGE_STRINGS['Loading.Indicator']}</>
            );
          }}
        </SplitTreatments>
      </SplitClient>
    </SplitFactory>
  );
};

DigitalTwinLayout.propTypes = {
  isFromOldApp: PropTypes.bool,
  children: PropTypes.node,
};

export default DigitalTwinLayout;
