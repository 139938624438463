import { Box, styled, Typography } from '@mui/material';

export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
};

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
}));

export const DigitalTwinContent = styled(Box)(() => ({
  height: '100%',
}));

export const AllPlantsAndPlantNameTypography = styled(Typography)(
  ({ theme }) => ({
    ...typographyStyles,
    textAlign: 'left',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  })
);

export const DigitalTwinTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  color: theme.palette.text.secondary,
}));

export const DigitalTwinContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobileView',
})(({ theme, isMobileView }) => ({
  height: 'calc(100vh - 3.5rem)',
  backgroundColor: theme.palette.background.default,
  padding: !isMobileView ? '1.118rem 1.5rem' : '1.5rem 1rem 1rem 0.875rem',
  overflowY: 'scroll',
}));

export const DigitalTwinTitleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobileView',
})(({ isMobileView }) => ({
  marginBottom: isMobileView ? '0.625rem' : '0.313rem',
}));

export const PlantTitleTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '1.5rem',
  lineHeight: '133.4%;',
  color: theme.palette.text.primary,
}));

export const DigitalTwinTitleDetailsToggleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobileView',
})(({ isMobileView }) => ({
  display: 'flex',
  flexDirection: !isMobileView ? 'row' : 'column',
  marginBottom: '1rem',
}));

export const DigitalTwinTitleAndDetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const ToggleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobileView',
})(({ isMobileView }) => ({
  marginLeft: !isMobileView ? 'auto' : null,
  marginTop: !isMobileView ? '0.875rem' : '1rem',
}));

export const DigitalTwinDetailsContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
}));

export const DigitalTwinDetailsTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '1rem',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: theme.palette.text.secondary,
}));

export const PlantTimeDetailsBox = styled(Box)(() => ({
  marginLeft: '0.25rem',
}));

export const DividerContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  margin: 'auto 1rem',
}));

export const DividerLine = styled(Box)(({ theme }) => ({
  width: '1px',
  backgroundColor: theme.palette.text.secondary,
  height: '0.875rem',
}));

export const DigitalTwinSceneContainer = styled(Box)(({ theme }) => ({
  height: '45rem',
  padding: '1.25rem',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.25rem',
}));

export const SceneContainer = styled(Box)(({ theme }) => ({
  '>div': {
    height: '100%',
    width: '100%',
    position: 'static',
  },
  height: 'inherit',
  '*': {
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '0.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '0.5rem',
    },
  },
}));
