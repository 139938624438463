import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Layout from '.';
import DigitalTwinScene from '../DigitalTwin/scene';

const DigiTwin = (props) => {
  const navigate = useNavigate();
  return (
    <Layout>
      <DigitalTwinScene navigate={navigate} {...props} />
    </Layout>
  );
};

const DigitalTwinForOldApp = (props) => {
  return (
    <Layout isFromOldApp>
      <DigitalTwinScene {...props} />
    </Layout>
  );
};

DigitalTwinForOldApp.propTypes = {
  isFromOldApp: PropTypes.bool,
};

export { DigiTwin, DigitalTwinForOldApp };
