import { config as globalConfig } from '@smf/ui-main-container-app';

const queryNames = {
  GET_TWINMAKER_HIERARCHY: 'GET_TWINMAKER_HIERARCHY',
  GET_DIGITAL_TWIN_CONFIG: 'GET_DIGITAL_TWIN_CONFIG',
  GET_TIME_ZONE: 'GET_TIME_ZONE',
  GET_HIERARACHY: 'GET_EQUIPMENT_HIERARCHY',
};

const SCENE_MODE = {
  EDITING: 'Editing',
  VIEWING: 'Viewing',
};

const GENERIC_CONSTANTS = {
  REFRESH_RATE: 300000,
  SCENE_DURATION: '5 m',
  ACTIVE_CAMERA: 'Camera1',
};

// eslint-disable-next-line  no-template-curly-in-string
const USER_POOL_ID_IDENTIFIER = '${USER_POOL_ID}';

const DATA_TEST_IDS = {
  SCENE_CONTAINER: 'scene-container',
  DIGITAL_TWIN_CONTAINER: 'digital-twin-container',
  ALL_PLANTS: 'all-Plants',
  PLANT_NAME_BOX: 'plant-name-box',
  PLANT_NAME_TEXT: 'plant-name-text',
  DIGITAL_TWIN_BOX: 'digital-twin',
  DIGITAL_TWIN_TITLE: 'digital-twin-title',
  PLANT_TIME: 'plant-time',
  LIVE_DATA: 'live-data',
  MOCK_SMF_TOGGLE: 'mock-smf-toggle',
};

const URL_PATHS = {
  EQUIPMENT_OVERVIEW: '/equipment_overview',
  PLANT_OVERVIEW: '/plant_overview',
  COMMAND_CENTER: '/command_center',
  PO_SEQUENCING: '/production_order_sequencing',
  SMART_SPC: '/smart-spc',
  WSS_DIGITAL_TWIN: `wss://${globalConfig.BASE_WS_API_URL}/digitalTwinWS`,
};

const LINK_DESTINATIONS = {
  PLANT_OVERVIEW: 'Plant Overview',
  EQUIPMENT_OVERVIEW: 'Equipment Overview',
  COMMAND_CENTER: 'Command Center',
  PO_SEQUENCING: 'PO Sequencing',
  SMART_SPC: 'SmartSPC',
  ALL_PLANTS: 'All Plants',
};

export {
  queryNames,
  GENERIC_CONSTANTS,
  DATA_TEST_IDS,
  USER_POOL_ID_IDENTIFIER,
  URL_PATHS,
  SCENE_MODE,
  LINK_DESTINATIONS,
};
