export const getTimeFromTimezone = (timezone = 'EST') => {
  let datetime = new Date().toLocaleString('en-US', { timeZone: timezone });
  let date = new Date(datetime);
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  hours = hours.length === 1 ? `0${hours}` : hours;
  minutes = minutes.length === 1 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

export const getIsOverlayClicked = (event) => {
  return (
    event?.additionalComponentData?.length &&
    event.additionalComponentData[0].hasOwnProperty('dataBindingContexts')
  );
};

export const getNavHierarchy = (
  equipmentHierarchyData,
  entityIdToSearch,
  results = []
) => {
  if (!equipmentHierarchyData) return null;
  const finalResults = results;
  if (equipmentHierarchyData?.entityId === entityIdToSearch) return results;

  equipmentHierarchyData.entityChildren.forEach((entityChild) => {
    if (entityIdToSearch.includes(entityChild.entityId)) {
      finalResults.push({
        entityId: entityChild.entityId,
        eopName: `${entityChild.entityNumber} - ${entityChild.entityName}`,
        entityName: entityChild.entityName,
        entityKey: entityChild.entityKey,
      });
      getNavHierarchy(entityChild, entityIdToSearch, finalResults);
    }
  });

  return finalResults;
};

export const getEopNavData = (event, equipmentHierarchyData) => {
  let dashedEntityId;
  if (event?.additionalComponentData?.length) {
    const { dataBindingContexts } = event.additionalComponentData[0];
    dashedEntityId = dataBindingContexts[0]?.entityId;
  }
  if (dashedEntityId) {
    const splittedEntityId = dashedEntityId.split('-');
    const entityId = splittedEntityId.join('|');
    const entityNumber = splittedEntityId[splittedEntityId.length - 1];
    const navList = getNavHierarchy(equipmentHierarchyData, entityId);
    const clickedEntity = navList?.find((nav) => nav.entityId === entityId);
    if (navList && clickedEntity) {
      const { entityName, entityKey } = clickedEntity;
      return { entityId, entityNumber, entityKey, entityName, navList };
    }
  }
  return null;
};

export const getHierarchyMap = (response) => {
  const hierarchyMap = {};
  response?.forEach((res) => {
    const entityId = res.meta.entityId;
    if (!hierarchyMap[entityId]) {
      hierarchyMap[entityId] = [];
    }
    hierarchyMap[entityId].push(res);
  });
  return hierarchyMap;
};

export const getLatestDataMap = (wsRes, map) => {
  const webSocketMap = [];
  for (let tag of wsRes) {
    if (webSocketMap[tag.meta.entityId]) {
      webSocketMap[tag.meta.entityId].push(tag);
    } else {
      webSocketMap[tag.meta.entityId] = [tag];
    }
  }

  for (const key in map) {
    const tags = map[key];
    tags.forEach((tag) => {
      const matchingTags = webSocketMap[tag.meta.entityId];
      if (matchingTags) {
        matchingTags.forEach((matchingTag) => {
          if (matchingTag.meta.propertyName === tag.meta.propertyName) {
            tag.data = matchingTag.data;
          }
        });
      }
    });
  }
  return map;
};
